<script>
import {lookup2,formatLabelName} from '@/apis/common'
import {queryCheckList,updateCheckList} from '@/apis/serviceorder'
import DatePicker from "vue2-datepicker";
export default {
    props:{
        serviceOrder:Object,
        show: {
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            submitted:false,
            checkList:[],
            assessmentId:"",
            reportPostDeadline:""
        }
    },
    components: {DatePicker},
    watch:{
        "serviceOrder.serviceId"(newValue){
            if(newValue!=null){
                this.init(); 
            }
        },
        "serviceOrder.assessmentId"(newValue){
            if(newValue!=null){
                this.assessmentId = newValue
            }
        },
        "serviceOrder.reportPostDeadline"(newValue){
            if(newValue!=null){
                this.reportPostDeadline = newValue
            }
        },
        show(newValue){
            if(newValue){
                this.$refs.formModal.show()
            }else{
                this.$refs.formModal.hide()
            }
        }
    },
    methods:{
        async init(){
            let typeCode = ""
            if(this.serviceOrder.serviceType=="SLCP"){
                typeCode = "CHECKLIST-SLCP"
            }else{
                typeCode = "CHECKLIST-HIGG"
            }
            let lookupRawData = await lookup2(typeCode);
            this.checkList = lookupRawData[typeCode].map(item=>{return {...item,labelCn:item.label,status:"",updateDate:""}});
            this.loadData()
        },
        loadData(){
            queryCheckList(this.serviceOrder.serviceId).then((res)=>{
                if(res.data.length>0){
                    for(var i=0;i<this.checkList.length;i++){
                        for(var j=0;j<res.data.length;j++){
                        if(res.data[j].checkType==this.checkList[i].value){
                            this.checkList[i].status = res.data[j].status
                            this.checkList[i].updateDate = res.data[j].updateDate
                            this.checkList[i].updateUser = res.data[j].updateUser                
                        }
                        }
                    }
                }
            })            
        },
        handleClose(){
            this.$emit('close',{assessmentId:this.assessmentId,reportPostDeadline:this.reportPostDeadline})
        },
        handleSaveAssessmentId(){
            updateCheckList({
                "operationType":"assessmentId",
                "serviceId":this.serviceOrder.serviceId,
                "assessmentId":this.assessmentId,
                "reportPostDeadline":this.reportPostDeadline
            })
        },
        handleConfirm(item){
            updateCheckList({
                "operationType":"checklist",
                "serviceId":this.serviceOrder.serviceId,
                "checkType":item.value,
            }).then((res)=>{
              item.status = res.data.status
              item.updateDate = res.data.updateDate
            })
        },
        formatLabelName
    }
};
</script>
<template>
    <b-modal
    id="modal-center"
    ref="formModal"
    centered
    :title='$t("serviceOrder.checklist")'
    title-class="font-18"
    hide-footer
    @hidden="handleClose"
    >
    <div class="row mb-2">
        <div class="row">
            <div class="col-md-4"><label class="form-label">{{$t("serviceOrder.assessmentId")}}</label></div>
            <div class="col-md-6"><input type="text" class="form-control form-control-sm" v-model="assessmentId"></div>
            <div class="col-md-2"></div>            
        </div>
        <div class="row mb-2">
            <div class="col-md-4"><label class="form-label">{{$t("serviceOrder.reportPostDeadline")}}</label></div>
            <div class="col-md-6">
                <date-picker
                    v-model="reportPostDeadline"
                    format="YYYY-MM-DD"
                    value-type="format"
                    :first-day-of-week="1"
                    lang="en"
                ></date-picker>
            </div>
            <div class="col-md-2"><button type="button" class="btn btn-primary btn-sm" @click="handleSaveAssessmentId">{{$t("actions.save")}}</button></div>            
        </div>        
        <table class="table align-middle table-nowrap table-hover mb-0">
            <thead class="table-light">
                <tr>
                    <th scope="col">检查点</th>
                    <th scope="col">状态</th>
                    <th scope="col">完成时间</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in checkList" :key="item.value">
                    <td>{{formatLabelName(item)}}</td>
                    <td><span style="color: green;" v-if="item.status!=''"><i class="fas fa-check-circle"></i>完成</span><span style="cursor: pointer;"  v-if="item.status==''" @click="handleConfirm(item)"><i class="fas fa-check-circle"></i>未完成</span></td>
                    <td>{{item.updateDate}}</td>
                </tr>
            </tbody>
        </table> 
    </div><!-- end row -->
    </b-modal>
</template>
<style lang="scss">
.mx-input-wrapper .mx-input{
    height:30px !important;
}
</style>
.mx-input 