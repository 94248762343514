<script>
import {queryServiceWorkFile,deleteWorkFile,queryNewServiceWorkFile,updateServiceOrderFile} from '@/apis/serviceorder'
import {downFile,fileSize,iconClass,lookup2,formatLabelName} from '@/apis/common'
import {uploadFile} from '@/apis/file'
import simplebar from "simplebar-vue"
import vue2Dropzone from "vue2-dropzone"
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  components: {simplebar,vueDropzone: vue2Dropzone},
  data() {
    return {
      uploadFileList:[],
      workFileList:[],
      workFiles:[],
      error:false,
      errorMsg:"",
      saveFileItem:{},
      newVersion:false,
      showUpload:false,
      dropzoneOptions: {
        url: "/api-gateway/service/uploadWorkFile",
        headers: { "Authorization": localStorage.getItem("auth.token")},
        thumbnailWidth: 80,
        maxFilesize: 100,
        addRemoveLinks: true,
        paramName:"files",
      },
    };
  },
  computed:{
  },
  watch: {
    "serviceOrder.serviceId"(newValue){
      if(newValue!=null){
        if(this.serviceOrder.serviceStatus!="F"){
            this.showUpload = true
        }        
        this.loadData();
      }
    },
    "serviceOrder.serviceDate"(newValue){
      if(newValue!=null){
        let serviceDateTimestamp = new Date(newValue).getTime()
        let newVersionDateTimestamp = new Date("2024-12-20").getTime()
        if(serviceDateTimestamp>newVersionDateTimestamp && this.serviceOrder.serviceType=="SLCP"){
          this.newVersion = true
          this.showUpload = false
        }else{
          if(this.serviceOrder.serviceStatus!="F"){
            this.showUpload = true
          }
        }
      }
    }
  },
  created(){
    this.init();
  },
  mounted() {
  },
  methods: {
    async init(){
      this.lookupRawData = await lookup2("WORK-FILE-TYPE");
      this.workFiles = this.lookupRawData["WORK-FILE-TYPE"].map(item=>{return {...item,labelCn:item.label,fileOrgName:"",remark:""}});
    },
    handleDownload (item){
        const url=`/api-gateway/service/download?fileType=SERVICE&fileName=${item.fileOrgName}`
        downFile(url,item.fileName);
    },
    handleDelete(fileName){
      let serviceId = this.serviceOrder.serviceId
      this.workFileList.forEach((item, index) => {
        if (item.fileOrgName==fileName) {
          deleteWorkFile({"id":serviceId,"type":"workFile",fileName:fileName}).then((res)=>{
            if(res.data){
              this.workFileList.splice(index, 1);
            }
          });         
        }
      });
    },
    handlerFileSending: function (file, xhr, formData) {
        formData.append("type","workFile")
        formData.append("id",this.serviceOrder.serviceId)
    },
    // eslint-disable-next-line no-unused-vars
    handlerFileSendingComplete: function (file, xhr, formData) {
        let uploadFile = JSON.parse(file.xhr.response).data[0]
        this.workFileList.push(uploadFile)
        this.uploadFileList.push(file)
    },
    removeUploadFile(file){
      let uploadFile = JSON.parse(file.xhr.response).data[0]
      this.handleDelete(uploadFile.fileOrgName)
    },
    loadData(){
      queryServiceWorkFile(this.serviceOrder.serviceId).then((res)=>{
        this.workFileList = res.data
      })
      queryNewServiceWorkFile(this.serviceOrder.serviceId).then((res)=>{
        if(res.data.length>0){
          for(var i=0;i<this.workFiles.length;i++){
            for(var j=0;j<res.data.length;j++){
              if(res.data[j].fileType==this.workFiles[i].value){
                this.workFiles[i].fileOrgName = res.data[j].fileOrgName
                this.workFiles[i].remark = res.data[j].remark
                this.workFiles[i].updateDate = res.data[j].updateDate
                this.workFiles[i].updateUser = res.data[j].updateUser                
              }
            }
          }
        }
      })
    },
    fileSize,
    iconClass,
    handleSaveFile(item){
        this.saveFileItem = item
        this.$refs.attachment.click()
    },
    changeAttachmentFile(){
        if(this.$refs.attachment.files.length==1){
            const formData = new FormData();
            formData.append("files",this.$refs.attachment.files[0]);
            uploadFile(formData).then((res)=>{
                let fileName = res.data[0].fileOrgName
                updateServiceOrderFile({serviceId:this.serviceOrder.serviceId,fileType:this.saveFileItem.value,fileOrgName:fileName,remark:this.saveFileItem.remark}).then((res)=>{
                  for(var i=0;i<this.workFiles.length;i++){
                    if(this.workFiles[i].value ==this.saveFileItem.value){
                      this.workFiles[i].fileOrgName = res.data.fileOrgName  
                      this.workFiles[i].remark = res.data.remark                            
                      this.workFiles[i].updateDate = res.data.updateDate
                      this.workFiles[i].updateUser = res.data.updateUser              
                      return true
                    }
                  }
                })
            })
        }
    },
    handleSaveRemark(item){
      updateServiceOrderFile({serviceId:this.serviceOrder.serviceId,fileType:item.value,fileOrgName:item.fileOrgName,remark:item.remark}).then((res)=>{
        for(var i=0;i<this.workFiles.length;i++){
          if(this.workFiles[i].value ==res.data.fileType){                  
            this.workFiles[i].updateDate = res.data.updateDate
            this.workFiles[i].updateUser = res.data.updateUser              
            return true
          }
        }
      })
    },
    formatLabelName
  }
};
</script>

<template>
  <div class="mb-0">
    <div class="row">
      <div v-if="newVersion">
        <table class="table align-middle table-nowrap table-hover mb-0">
            <thead class="table-light">
                <tr>
                    <th scope="col">文件</th>
                    <th scope="col">备注</th>
                    <th scope="col">上传者</th>
                    <th scope="col">上传时间</th>
                    <th scope="col">操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in workFiles" :key="item.value">
                    <td>{{formatLabelName(item)}}</td>
                    <td><input type="text" class="form-control form-control-sm" v-model="item.remark"></td>
                    <td>{{item.updateUser}}</td>
                    <td>{{item.updateDate}}</td>
                    <td>                      
                      <a  @click="handleSaveRemark(item)" href="javascript:" >{{$t("actions.save")}}</a>       
                      <a  @click="handleSaveFile(item)" href="javascript:" class="ms-2">{{$t("actions.upload")}}</a>       
                      <a  @click="handleDownload(item)" href="javascript:" v-if="item.fileOrgName!=''" class="ms-2">{{$t("actions.download")}}</a>
                    </td>
                </tr>
            </tbody>
        </table> 
        <input ref="attachment" type="file" placeholder="" style="display: none;" @change="changeAttachmentFile">         
      </div>
      <div v-if="!newVersion">
        <div v-if="workFileList.length>0">
                                    <div class="table-responsive">
                                        <simplebar class="h-100">
                                            <table class="table align-middle table-nowrap table-hover mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th scope="col">File Name</th>
                                                        <th scope="col">Upload Date</th>
                                                        <th scope="col">Size</th>
                                                        <th scope="col"><a href="javascript:"  @click="loadData"><i class="ion ion-md-refresh me-1 align-middle" ></i> {{$t("actions.refresh")}}</a></th>
                                                    </tr>
                                                    </thead>
                                                <tbody>
                                                    <tr v-for="item in workFileList" :key="item.fileOrgName">
                                                        <td><a @click="handleDownload(item)" href="javascript:" class="text-dark fw-medium"><i class="mdi font-size-16 align-middle me-2" :class="iconClass(item.fileName)"></i> {{item.fileName}}</a></td>
                                                        <td>{{item.createDate}}</td>
                                                        <td>{{fileSize(item.size)}}</td>
                                                        <td>
                                                            <a  @click="handleDownload(item)" href="javascript:">{{$t("common.download")}}</a>
                                                            <a  @click="handleDelete(item.fileOrgName)" href="javascript:" class="ms-2" v-if="showUpload">{{$t("common.delete")}}</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </simplebar>
                                    </div>
                                </div>
        <div class="card-body" v-if="showUpload">
            <div class="col-lg-12 col-sm-12">
              <h4 class="card-title">{{$t("serviceOrder.uploadFile")}}</h4>
              <div class="mb-5">
              <!-- file upload -->
              <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions"
              @vdropzone-sending="handlerFileSending"
              @vdropzone-complete="handlerFileSendingComplete"
              @vdropzone-removed-file="removeUploadFile"
              ></vue-dropzone>
              </div>
            </div>
        </div>
      </div>      
    </div>
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>