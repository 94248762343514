import request from '@/request'
export const queryStatement = (data) => {
    return request({
        url:`/api-gateway/service/queryStatement/${data.pageSize}/${data.pageNum}`,
        method:'post',
        data,
    });
}
export const saveStatement = (data) => {
    return request({
        url:'/api-gateway/service/saveStatement',
        method:'post',
        data,
    });
}
export const queryStatementInfo = (statementId) => {
    return request({
        url:`/api-gateway/service/queryStatementInfo/${statementId}`,
        method:'get'
    });
}
export const checkStatement = (statementId) => {
    return request({
        url:`/api-gateway/service/checkStatement/${statementId}`,
        method:'get'
    });
}
export const syncStatmentStatus = (statementId) => {
    return request({
        url:`/api-gateway/service/syncStatementStatus/${statementId}`,
        method:'get'
    });
}

