const vn={
    common:{
        yes:"Yes",
        no:"No",
        verfRequirement:"Verification Requirement",
        baseInfo:"Basic information of verified factory",
        clientNameLocal:"Registered Company Name (local language)",
        clientNameLocalError:"Please enter the company name",
        clientNameEn:"Registered Company Name (English)",
        addressLocal:"Company Address (local language)",
        addressLocalError:"Please enter the address",
        addressEn:"Company Address (English)",
        industry:"Industry",
        businessType:"Company type",
        pleaseDetail:"Please enter",
        contactInfo:"Contact Information",
        contact:"Name",
        contactError:"Please enter the contact name",
        mobile:"Telephone",
        mobileError:"Please enter the contact number",
        email:"E-mail Address",
        emailError:"Please enter the E-mail Address",
        title:"Position",
        titleError:"Please enter the position of contact person",
        submit:"Gửi",
        hotline:"Đường dây nóng",
        moreService:"More service",
        successMsg:"Well received the application with many thanks. We will check it and contact with you as soon as possible. Service Contact Number: 13662650451",
        view:"View",
        selectFile:"Select File",        
        verificationDate:"Verification Date",
        pleaseSelectFile:"Please select File",
        download:"Tải về",
        delete:"Delete",
        uploadFile:"Upload file"
    },
    fem:{
        formName:"Higg FEM Verification Application Form",
        type:"Verification Type",
        typeFoundation:"Higg Foundation - No Scoring, Verify the data and records in last 12 months",
        typeFEM:"Higg FEM - With scoring, verify the data and records in last calendar year",
        self:"Verification Level",
        envManagementSystem:"Environmental Management System",
        energyGreenhouseGases:"Energy Use and GHG Emission",
        waterResources:"Water Use",
        wastewater:"Wastewater",
        exhaustEmissions:"Emissions to Air",
        waste:"Waste Management",
        chemicalManagement:"Chemical Management",
        experience:"Verification Experience",
        experience1:"First",
        experience2:"Secondary",
        experience3:"Third Time and above",
        model:"Verification Mode",
        model1:"Onsite Verification",
        model2:"Offsite Verification(*Please check with your client, advance agreement is required)",
        selfassessment:"Is the self-assessment posted",
        promoteBrand:"Brands",
        auditDate:"Expected Verification Date",
        auditDateError:"Please enter the Expected verification Date",
        productType:"Product type and annual shipment volume",
        technology:"Main product process (Please provide production process flow instead if applicable)",
        chemical:"Does the factory use chemical in production processes? Please specify if applicable",
        wwt:"Does the factory have wastewater treatment plant?",
        coverarea:"Factory size (floor area)",
        coverareaError:"Please enter the factory size",
        employee:"NO. of employees",
        employeeError:"Please enter the number",
        esc:"Environmental management system certificates, please specify"
    },
    slcp:{
        formName:"SLCP Verification Application Form",
        type:"Verification Step",
        experience:"Verification Experience",
        experience1:"First Time",
        experience2:"Annual Time",
        experience3:"Follow-up",
        selfassessment:"Does the facility submit the self-assessment? ",
        postDate:"Submit Date",
        ahSystem:"AH platform selection",
        other:"Other",
        promoteBrand:"Brand",
        announcementType:"Verification Announcement",
        announcementType1:"Announced",
        announcementType2:"Unannounced",
        announcementType3:"Semi-Announced with 10-day window",
        auditDate:"Expected Verification Date",
        auditDateError:"Please enter the Expected verification Date",
        model:"Verification Type",
        model1:"Onsite Verification",
        model2:"Full Virtual Verification",
        model3:"Virtual Verification+ Onsite Verification",
        apsca:"Is APSCA verifier requested?",
        employee:"workers",
        employeeError:"Please enter the number of workers",
        manager:"supervisors",
        csr:"CSR management system certificates (e.g.: BSCI, WRAP, ISO and etc.)",
        serviceProvider:"Onsite Service Providers (e.g. security, janitor, cleaner)",
        productionBuildings:"Numbers of building-production",
        dormitories:"Numbers of buildings-Dormitory",
        warehouses:"Numbers of buildings-Warehouse",
        otherBuildings:"Numbers of buildings-Other",
        productType:"Main product type",
        technology:"Main production processes",
        times:"Times"
    },
    application_confirm:{
        applicationForm:"Application form",
        applicationFormUpload:"Application Confirmation",
        notExist:"Application form does not exist",
        error:"Application form exceptions",
        success:"Application has been submitted, we will provide quotations as soon as possible, service hotline: 13662650451",
        submitError:"Application submission exception, please contact us: 13662650451",
        pleaseSelectfile:"Please select the signed and stamped application form"
    },
    confirm_letter:{
        confirmLetter:"Confirmation Letter Return Receipt",
        notExist:"Service order does not exist",
        error:"Service order exceptions",
        success:"Scheduling has been confirmed, Service hotline: 13662650451",
        submitError:"To submit an exception, please contact us: 13662650451",
    },
    verfDocument:{
        verfDocument:"Verification Documents",
        pre:"Pre-review Documents",
        other:"Other Documents",
        file:"File",
        updateTime:"Update time",
        size:"Size",
        status:"Status",
        comments:"Comments",
        preTitle:"Pre-review document list",
        pre1:"Energy: Energy consumption records of past three years(2021-2024), Energy/Fuel purchasing invoices or meter reading records (2021-2024).",
        pre2:"Water: Water sources list and water consumption records of past three years (2021-2024), water purchasing invoices or meter reading records (2021-2024).",
        pre3:"Wastewater: Industrial wastewater and/or domestic wastewater quantity tracking records(2024), Invoices or meter reading records(2024), estimating methods if applicable.",
        pre4:"Air Emissions: tracking list of all kinds of air emissions(2024), Invoices or meter reading records(2024), estimating methods if applicable.",
        pre5:"Waste: Non-hazardous waste generation tracking list(2024), hazardous waste generation tracking list(2024), weight reading records/invoices/disposal records(2024), estimating records if applicable.",
    },
    traningSurvey:{
        title:"Bảng câu hỏi đào tạo bền vững",
        question1:"Câu số 1: Chủ đề đào tạo bạn đã tham gia",
        question2:"Câu số 2: Thời gian bạn tham gia khóa đào tạo",
        question3:"Câu số 3: Nội dung của buổi hội thảo này có trả lời các câu hỏi về các chương trình của bạn không?",
        question4:"Câu số 4: Nội dung buổi hội thảo này có cung cấp hướng dẫn thực hiện công việc của nhà máy của bạn không?",
        question5:"Câu số 5: Hội thảo này có phù hợp và truyền cảm hứng cho sự hiểu biết và thực hiện công tác quản lý môi trường của bạn khồng?",
        question6:"Câu số 6: Tài liệu hội thảo này có cấu trúc chặt chẽ và dễ tìm không?",
        question7:"Câu số 7: Người trình bày có logic, rõ ràng và tập trung không?",
        question8:"Câu số 8: Người trình bày có kết hợp nhiều tình huống và thực hành không?",
        question9:"Câu số 9: Người trình bày có nắm bắt tốt tiến độ của hội thảo không?",
        question10:"Câu số 10: Xin vui lòng đánh giá chung về thái độ, tinh thần trách nhiệm và cách phục vụ của nhân viên hội thảo này.",
        question11:"Câu số 11: Vui lòng đánh giá mức độ tiện lợi, và sự dễ sử dụng của công cụ đào tạo.",
        question12:"Câu số 12: Nếu bạn có bất kỳ đề xuất hoặc bổ sung nào khác, vui lòng gửi cho chúng tôi.",
        nonExist:"Chương trình đào tạo không tồn tại",
        error:"Dữ liệu bất thường",
        success:"Cảm ơn phản hồi của bạn!",
        submiterror:"Các thông tin khác, vui lòng liên hệ với quản trị viên: 13662650451",
        nameerror:"Tên Khóa học không được để trống",
        dateerror:"Thời gian khóa học không được để trống"
    }
}
export default vn