<script>
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customService:[
        ],
        ourService:[
        ],
        greenpont:[
        ],
      suggestion:""
    };
  },
  mounted() {
    this.init();
  },
  watch:{
    "serviceOrder.survey"(){
        this.init()
    }
  },
  methods: {
    init(){
          this.suggestion = this.serviceOrder.survey.suggestion
          let survey = JSON.parse(this.serviceOrder.survey.star)
          this.customService.splice(0)
          this.customService.push(...survey.customService)
          this.ourService.splice(0)
          this.ourService.push(...survey.ourService)
          this.greenpont.splice(0)
          this.greenpont.push(...survey.greenpont)
          this.$forceUpdate()
    },
    labelSelect(item){
      if(item.i18n==null){
        if(this.$i18n.locale=="zh-CN"){
          return item.textCn;
        }else{
          return item.textEn;
        }
      }else{
        return this.$t("survey."+item.i18n)
      }
    }
  },
};
</script>

<template>
  <div class="mb-0">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="padding-top: 0px;">
                          <div class="card border shadow-none mb-5">
                                <div class="card-header d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="card-title">{{$t("survey.customerservice")}}</h5>
                                    </div>
                                </div>
                                <div class="card-body">
                                  <div class="row mb-3 justify-content-between" v-for="(item,index) in customService" :key="'cs'+index">
                                    <div class="col-lg-6" style="line-height: 30px;">{{labelSelect(item)}}</div>
                                    <div class="col-lg-4"><div class="star"
                                      :class="{
                                        'star1': item.star==1,
                                        'star2': item.star==2,
                                        'star3': item.star==3,
                                        'star4': item.star==4,
                                        'star5': item.star==5,
                                    }"     
                                      >{{ item.star }}</div></div>
                                  </div>
                                </div>
                          </div>
                          <div class="card border shadow-none mb-5">
                                <div class="card-header d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="card-title">{{$t("survey.ourservice")}}</h5>
                                    </div>
                                </div>
                                <div class="card-body">
                                  <div class="row mb-3 justify-content-between" v-for="(item,index) in ourService" :key="'os'+index">
                                    <div class="col-lg-6" style="line-height: 30px;">{{labelSelect(item)}}</div>
                                    <div class="col-lg-4"><div class="star"
                                      :class="{
                                        'star1': item.star==1,
                                        'star2': item.star==2,
                                        'star3': item.star==3,
                                        'star4': item.star==4,
                                        'star5': item.star==5,
                                    }"     
                                      >{{ item.star }}</div></div>
                                  </div>
                                </div>
                          </div>
                          <div class="card border shadow-none mb-5">
                                <div class="card-header d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="card-title">{{$t("survey.greenpont")}}</h5>
                                    </div>
                                </div>
                                <div class="card-body">
                                  <div class="row mb-3 justify-content-between" v-for="(item,index) in greenpont" :key="'g'+index">
                                    <div class="col-lg-6" style="line-height: 30px;">{{labelSelect(item)}}</div>
                                    <div class="col-lg-4"><div class="star"
                                      :class="{
                                        'star1': item.star==1,
                                        'star2': item.star==2,
                                        'star3': item.star==3,
                                        'star4': item.star==4,
                                        'star5': item.star==5,
                                    }"     
                                      >{{ item.star }}</div></div>
                                  </div>
                                </div>
                          </div>
                            <div class="card border shadow-none mb-1">
                                <div class="card-header d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="card-title">{{$t("survey.suggestion")}}</h5>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div>
                                        <div class="row">{{ suggestion }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
.star{
  width: 60px;
  line-height: 18px;
  text-align: center;
}
.star1{
  background-color: rgb(186, 186, 186);
  color: #000;
}
.star2{
  background-color: rgb(245, 195, 87);
  color: #000;
}
.star3{
  background-color: rgb(255, 172, 90);
  color: white;
}
.star4{
  background-color: rgb(193, 215, 89);
  color: white;
}
.star5{
  background-color: rgb(72, 150, 77);
  color: white;
}
</style>