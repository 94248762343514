<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import simplebar from "simplebar-vue";
import gpselect from "../../components/gpselect";
import {lookup2,trueText,downFile,getLookupItemText} from '@/apis/common'
import {queryStatement,saveStatement,queryStatementInfo,checkStatement,syncStatmentStatus} from '@/apis/statement'
import {uploadFile} from '@/apis/file'
import {
  required
} from "vuelidate/lib/validators";
export default {
  page: {
    title: "Statement",
    meta: [{ name: "description", content: appConfig.description }]
  },
  validations: {
    form: {
      name: { 
        required,
      },
      type: { 
        required,
      },
      fileOrgName: { 
        required,
      },            
    },
  },  
  components: { Layout,simplebar,gpselect},
    data() {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            tableData: [],
            detailList:[],
            curData:{},
            showList:true,
            showDetail:false,
            submitted:false,
            curStatementId:0,
            filter:{
                name:"",
                type:"",
                status:""
            },
            lookupRawData:{},
            lookupData:{
                status:[],
                detailStatus:[]
            },
            pageRequest:{
                pageNum:1,
                pageSize:10
            },
            form:{
                name:"",
                type:"",
                fileOrgName:"",
            },
            formInit:{
            }
        };
    },
    computed: {
    },
    watch:{
        "pageRequest.pageNum"() {
            this.loadData()
        },
        filter:{
            handler(){
                this.loadData();
            },
            deep:true
        },
        '$store.state.auth.language': function() {
            this.changeLookupLanguage()
        }
    },
    created(){
        this.formInit = {...this.form}
        this.init();
        this.loadData();
    },
    methods: {
        trueText,
        async init(){
            this.lookupRawData = await lookup2("STM-STATUS,STMI-STATUS");
            this.lookupData.status = this.lookupRawData["STM-STATUS"].map(item=>{return {...item,labelCn:item.label}});
            this.changeLookupLanguage()
        },
        changeLookupLanguage(){
            if(localStorage.getItem('language')=="zh-CN"){
                this.lookupData.status = this.lookupData.status.map(item=>{return {...item,label:item.labelCn}});
            }else{
                this.lookupData.status = this.lookupData.status.map(item=>{return {...item,label:item.labelEn}});
            }
        },    
        getParams(){
            let params = {...this.pageRequest,filter:this.filter}
            return params
        },
        loadData(){
            const params = this.getParams();
            queryStatement(params).then((res)=>{
                this.tableData = res.data;
                this.totalRows = res.page.total;
            });
        },
        closeForm(){
            this.$refs.newFormModal.hide()
        },        
        handleNew(){
            if(this.$refs.attachment.files.length>0){
                this.$refs.attachment.value = null
            }            
            this.submitted=false
            this.form = {...this.formInit}
            this.$refs.newFormModal.show()
        },
        handleSaveFormFile(){
            this.$refs.attachment.click()
        },
        changeAttachmentFile(){
            if(this.$refs.attachment.files.length==1){
                const formData = new FormData();
                formData.append("files",this.$refs.attachment.files[0]);
                uploadFile(formData).then((res)=>{
                    this.form.fileOrgName = res.data[0].fileOrgName
                })
            }
        },
        handleSaveStatement(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            } else {
                saveStatement(this.form).then(()=>{
                this.loadData();
                this.$refs.newFormModal.hide();
                Vue.swal(this.$t("statement.started"));
            })
            }
        },
        handleDownload (fileName){
            const url=`/api-gateway/service/download?fileType=ATT&fileName=${fileName}`
            downFile(url,"");
        },
        formatLookupText(typeCode,value){
            return getLookupItemText(this.lookupRawData[typeCode],value);
        },
        handleDetail(statementId,type){
            this.detailList.splice(0)
            this.curStatementId = statementId
            queryStatementInfo(statementId).then((res)=>{
                this.detailList = res.data;
                if(type==1){
                    this.showList = false;
                    this.showDetail = true;
                }
            });
        },
        handleBack(){
            this.showDetail = false;
            this.showList = true;
            this.loadData();
        },
        handleCheck(){
            checkStatement(this.curStatementId).then(()=>{
                Vue.swal(this.$t("statement.started"));
            });
        },
        handleSync(){
            syncStatmentStatus(this.curStatementId).then(()=>{
                Vue.swal(this.$t("statement.syncFinished"));
            });
        }
    }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body" v-if="showList">
                    <div class="row">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <h5 class="card-title">{{$t("statement.pageName")}}</h5>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                                    <button type="button" class="btn btn-light text-truncate ms-2"  @click="loadData"><i class="ion ion-md-refresh me-1 align-middle"></i> {{$t("actions.refresh")}}</button>
                                    <button type="button" class="btn btn-info text-truncate ms-2"  @click="handleNew">{{$t("actions.new")}}</button>
                                </div>
                            </div>
                        </div><!-- end row -->
                        <div class="table-responsive">
                            <simplebar class="h-100">
                            <table class="table align-middle table-nowrap table-check">
                                <thead>
                                    <tr>
                                        <th scope="col">{{$t("statement.type")}}</th>
                                        <th scope="col">{{$t("statement.name")}}</th>
                                        <th scope="col">{{$t("statement.status")}}</th>
                                        <th scope="col">{{$t("common.updateDate")}}</th>
                                        <th style="width: 80px; min-width: 80px;">{{$t("actions.actions")}}</th>
                                    </tr><!-- end tr -->
                                </thead><!-- end thead -->
                                <tbody>
                                    <tr class="data-filter">
                                        <td>
                                            <input type="text" class="form-control" v-model="filter.type">
                                        </td>                                
                                        <td>
                                            <input type="text" class="form-control" v-model="filter.name">
                                        </td>
                                        <td>
                                            <gpselect
                                            :data="lookupData.status"
                                            v-model="filter.status"
                                            />
                                        </td>
                                        <td>                                    
                                        </td>
                                        <td>                                       
                                        </td>
                                    </tr><!-- end tr -->
                                    <tr v-for="row in tableData" :key="row.id">
                                        <td>{{row.type}}</td>
                                        <td><a class="text-body fw-medium" href="javascript:" @click="handleDetail(row.id,1)">{{row.name}}</a></td>
                                        <td>{{formatLookupText('STM-STATUS',row.status)}}</td>
                                        <td>{{row.createDate}}</td>
                                        <td><a class="text-body fw-medium" href="javascript:" @click="handleDetail(row.id,1)">{{$t("common.view")}}</a>                                           
                                        </td>
                                    </tr><!-- end tr -->
                                </tbody><!-- end tbody -->
                            </table><!-- end table -->
                            </simplebar>
                        </div>
                        <div class="row g-0 text-center text-sm-start" v-if="totalRows>0">
                            <div class="col-sm-6">
                                <div>
                                    <p class="mb-sm-0">Showing {{(pageRequest.pageNum-1)*pageRequest.pageSize+1}} to {{pageRequest.pageNum*pageRequest.pageSize}} of {{totalRows}} entries</p>
                                </div>
                            </div>
                            <!-- end col -->
                            <div class="col-sm-6">
                                <ul class="pagination pagination-rounded justify-content-center justify-content-sm-end mb-sm-0">
                                    <b-pagination v-model="pageRequest.pageNum" :total-rows="totalRows" :per-page="pageRequest.pageSize"></b-pagination>
                                </ul><!-- end ul -->
                            </div><!-- end col -->
                        </div><!-- end row -->        
                    </div><!-- end card -->                    
                </div>
                <div class="card-body" v-if="showDetail">
                    <div class="row">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <h5 class="card-title">{{$t("statement.pageName")}}</h5>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                                    <button type="button" class="btn btn-light text-truncate ms-2"  @click="handleDetail(curStatementId,2)"><i class="ion ion-md-refresh me-1 align-middle"></i> {{$t("actions.refresh")}}</button>
                                    <button type="button" class="btn btn-info text-truncate ms-2"  @click="handleCheck">{{$t("statement.check")}}</button>
                                    <button type="button" class="btn btn-info text-truncate ms-2"  @click="handleSync">{{$t("statement.sync")}}</button>
                                    <button type="button" class="btn btn-info text-truncate ms-2"  @click="handleBack">{{$t("actions.back")}}</button>
                                </div>
                            </div>
                        </div><!-- end row -->
                        <table class="table align-middle table-nowrap table-check">
                                <thead>
                                    <tr>
                                        <th scope="col">{{$t("statement.index")}}</th>
                                        <th scope="col">{{$t("statement.assessmentId")}}</th>
                                        <th scope="col">{{$t("statement.facilityName")}}</th>
                                        <th scope="col">{{$t("statement.serviceNo")}}</th>
                                        <th scope="col">{{$t("statement.status")}}</th>
                                    </tr><!-- end tr -->
                                </thead><!-- end thead -->
                                <tbody>
                                    <tr v-for="(row,index) in detailList" :key="row.id">
                                        <td>{{index+1}}</td>
                                        <td>{{row.assessmentId}}</td>
                                        <td>{{row.facilityName}}</td>
                                        <td>{{row.serviceNo}}<span style="color: green;" v-if="row.status=='NP'"><i class="fas fa-check-circle"></i></span><span style="color: red;" v-if="row.status=='PD'"><i class="fas fa-times"></i></span></td>
                                        <td>{{formatLookupText('STMI-STATUS',row.status)}}</td>
                                    </tr><!-- end tr -->
                                </tbody><!-- end tbody -->
                            </table><!-- end table -->
                    </div><!-- end card -->                    
                </div>
            </div>
        </div><!-- end col -->
    </div><!-- end row -->
    <input ref="attachment" type="file" aria-label="Upload your file" placeholder="" style="display: none;" @change="changeAttachmentFile">     
    <b-modal id="newFormModal" :title="$t('statement.add')" title-class="font-18" ref="newFormModal" hide-footer>                    
        <form class="needs-validation">
        <div class="row">
                <div class="col-md-4 mb-3">
                    {{$t("statement.name")}}
                </div>            
                <div class="col-md-8 mb-3">
                    <input type="text" class="form-control"  v-model="form.name" 
                    :class="{
                        'is-invalid': submitted && $v.form.name.$error,
                    }"                    
                    />
                    <div
                        v-if="submitted && $v.form.name.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.form.name.required">
                                {{$t("common.requiredError")}}.
                            </span>
                    </div>                    
                </div>
                <div class="col-md-4 mb-3">
                    {{$t("statement.type")}}
                </div>            
                <div class="col-md-8 mb-3">
                    <select class="form-select" v-model="form.type"
                    :class="{
                        'is-invalid': submitted && $v.form.type.$error,
                    }"                        
                    >
                        <option value="SLCP">SLCP</option>
                        <option value="Higg">Higg FEM</option>                   
                    </select>
                    <div
                        v-if="submitted && $v.form.type.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.form.type.required">
                                {{$t("common.requiredError")}}.
                            </span>
                    </div>                          
                </div>            
                <div class="col-md-4 mb-3">
                    {{$t("statement.file")}}
                </div>            
                <div class="col-md-5 mb-3">
                    <input type="text" class="form-control"  v-model="form.fileOrgName" disabled
                    :class="{
                        'is-invalid': submitted && $v.form.fileOrgName.$error,
                    }"                    
                    />
                    <div
                        v-if="submitted && $v.form.fileOrgName.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.form.fileOrgName.required">
                                {{$t("common.requiredError")}}.
                            </span>
                    </div>                    
                </div>                
                <div class="col-md-3 mb-3">
                    <button type="button" class="btn btn-info" @click="handleSaveFormFile">{{$t("common.selectFile")}}</button> 
                </div>    
                <div class="col-md-12 mb-3">
                    <button type="button" class="btn btn-info" @click="handleSaveStatement">{{$t("actions.ok")}}</button>
                    <button type="button" class="btn btn-light ms-1" @click="closeForm" >{{$t("actions.close")}}</button>
                </div>        
        </div>
        </form>    
    </b-modal>    
  </Layout>
</template>
<style lang="scss" scoped>
.auditorName::first-letter {
  text-transform: uppercase;
}
.border-light {
  border-color: #f8f7fc !important;
}
.bg-light {
    background-color: rgba(248, 247, 252,1)!important;
}
.search-box{
  .form-control{
      border-radius: 30px;
      padding-left: 40px;
  }
  .search-icon{
      font-size: 16px;    
      position: absolute;
      left: 13px;
      top: 2px;
      color: #959396;
      line-height: 38px;
  }
}
</style>