<script>
import simplebar from "simplebar-vue";
import gpselect from "./gpselect";
import Multiselect from "vue-multiselect";
import orderstatus from "./orderstatus";
import DatePicker from "vue2-datepicker";
import {lookup2,getLookupItemText,trueText,downFile} from '@/apis/common'
import {uploadFile} from '@/apis/file'
import {queryOrder,createContract,updateOrderFile,deleteOrder,createOrderInvoice} from '@/apis/order'
import Vue from "vue";
import Swal from "sweetalert2";
import store from '@/state/store'
export default {
    props:{
        showTitle: Boolean,
        clientNo: String
    },
    components: { simplebar,gpselect,orderstatus,DatePicker,Multiselect},
    data() {
        return {
            showType: "list",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            tableData: [],
            items:[],
            contractLanguage:[],
            contractLanguageList:[{languageName:"Chinese",languageCode:"CN"},{languageName:"English",languageCode:"EN"},{languageName:"Vietnamese",languageCode:"VN"}],            
            showForm:false,
            orderStatusShow:false,
            curOrder:null,
            saveFileAction:"",
            filter:{
                clientName:"",
                orderNo:"",
                orderType:"",
                orderStatus:"",
                orderSource:"",
                paymentStatus:"",
                invoiceStatus:"",
                promoteBrand:"",
                createDate:"",
                country:""
            },
            lookupRawData:{},
            lookupData:{
                orderType:[],
                orderStatus:[],
                orderSource:[],
                cnty:[],
                yesNo:[{label:"是",labelEn:"Yes",value:"Y"},{label:"否",labelEn:"No",value:"N"}]
            },
            pageRequest:{
                pageNum:1,
                pageSize:10
            }
        };
    },
    computed: {
        myHiggFemFormUrl(){
            return "http://application.greenpont.com/higg-form/"+store.state.auth.currentUser.userId
        },
        mySlcpFormUrl(){
            return "http://application.greenpont.com/slcp-form/"+store.state.auth.currentUser.userId
        },
        myAwsFormUrl(){
            return "http://application.greenpont.com/aws-form/"+store.state.auth.currentUser.userId
        },        
        myGeneralFormUrl(){
            return "http://application.greenpont.com/general-form/"+store.state.auth.currentUser.userId
        },
        myZdhcFormUrl(){
            return "http://application.greenpont.com/zdhc-level1-form/"+store.state.auth.currentUser.userId
        },
        myZeroFormUrl(){
            return "http://application.greenpont.com/zero-level2-form/"+store.state.auth.currentUser.userId
        },
        myGHGFormUrl(){
            return "http://application.greenpont.com/ghg-form/"+store.state.auth.currentUser.userId
        }
    },
    watch:{
        "pageRequest.pageNum"() {
            this.loadData()
        },
        filter:{
            handler(){
                this.loadData();
            },
            deep:true
        }
    },
    created(){
        this.profileFormInit = {...this.profileForm}
        this.init();
        this.loadData();
    },
    mounted() {
    },
    methods: {
        trueText,
        async init(){
            this.lookupRawData = await lookup2("ORDER-TYPE,ORDER-STATUS,ORDER-SOURCE,CNTY");
            this.lookupData.orderType = this.lookupRawData["ORDER-TYPE"];
            this.lookupData.orderStatus = this.lookupRawData["ORDER-STATUS"];
            this.lookupData.orderSource = this.lookupRawData["ORDER-SOURCE"];
            this.lookupData.cnty = this.lookupRawData["CNTY"];
        },
        getParams(){
            let params = {...this.pageRequest,filter:this.filter}
            if(this.clientNo!=undefined && this.clientNo!=null){
                params.filterExt = {"clientNo":this.clientNo}
            }
            return params
        },
        loadData(){
            const params = this.getParams();
            queryOrder(params).then((res)=>{
                this.tableData = res.data;
                this.totalRows = res.page.total;
            });
        },
        handleDetail(actionType,order){
            let orderPath="/"
            if(order.orderType=="Higg"){
                orderPath = "order-higg-form"
            }else if(order.orderType=="SLCP"){
                orderPath = "order-slcp-form"
            }else if(order.orderType=="AWS"){
                orderPath = "order-aws-form"
            }else if(order.orderType=="ZLR"){
                orderPath = "order-zero-level2-form"
            }else if(order.orderType=="ZDHC-IL1"){
                orderPath = "order-zdhc-level1-form"
            }else if(order.orderType=="GHG"){
                orderPath = "order-ghg-form"
            }else{
                orderPath = "order-general-form"
            }
            this.$router.push({
                name: orderPath,
                query: {
                    orderNo: order.orderNo,
                    clientNo:this.clientNo,
                    action:actionType
                }
            })
        },
        handleCreate(orderType){
            let orderPath="/"
            if(orderType=="higg"){
                orderPath = "order-higg-form"
            }else if(orderType=="slcp"){
                orderPath = "order-slcp-form"
            }else if(orderType=="aws"){
                orderPath = "order-aws-form"
            }else if(orderType=="general"){
                orderPath = "order-general-form"
            }else if(orderType=="zlr"){
                orderPath = "order-zero-level2-form"
            }else if(orderType=="zdhcil1"){
                orderPath = "order-zdhc-level1-form"
            }else if(orderType=="ghg"){
                orderPath = "order-ghg-form"
            }
            
            this.$router.push({
                name: orderPath,
                query:{
                    clientNo:this.clientNo
                }
            })
        },
        formatLookupText(typeCode,value){
            return getLookupItemText(this.lookupRawData[typeCode],value);
        },
        handleUpdateStatus(orderData){
            this.curOrder = orderData
            this.orderStatusShow = true;
        },
        handleFollowup(orderData){
            let orderPath="follow-up"
            this.$router.push({
                name: orderPath,
                query: {
                    id: orderData.orderNo,
                    type:'order',
                }
            })
        },
        handleInvoice(orderData){
            this.$router.push({
                name: "invoice",
                query: {
                    orderNo: orderData.orderNo,
                    clientId: orderData.clientId
                }
            })
        },
        handleCloseOrderStatusModal(){
            this.curOrder = null
            this.orderStatusShow = false
            this.loadData()
        },
        actionEnable(actionType,order){
            if(actionType=='Edit' || actionType=='Status' || actionType=='Follow' ){
                if(order.orderStatus=='C'||order.orderStatus=='F'){
                    return false;
                }else{
                    return true;
                }
            }
            if(actionType=="Invoice"){
                if(order.orderStatus!='N' && order.orderStatus!='FU'){
                    return true;
                }else{
                    return false;
                }
            }
            if(actionType=="Contract"){
                if((order.orderType=="Higg" || order.orderType=="SLCP")&&order.contractFile==null){
                    return true
                }else {
                    return false
                }
            }
            if(actionType=="SaveContractFile"){
                if(order.contractFile==null){
                    return true
                }else {
                    return false
                }
            }
            if(actionType=="DownContractFile"){
                if(order.contractFile!=null){
                    return true
                }else {
                    return false
                }
            }
            if(actionType=="CreateInvoice"){
                if((order.orderType=="Higg" || order.orderType=="SLCP")&&order.invoiceFile==null){
                    return true
                }else {
                    return false
                }
            }
            if(actionType=="SaveInvoiceFile"){
                if(order.invoiceFile==null){
                    return true
                }else {
                    return false
                }
            }
            if(actionType=="DownInvoiceFile"){
                if(order.invoiceFile!=null){
                    return true
                }else {
                    return false
                }
            }
            if(actionType=="DownFormFile"){
                if(order.formSignFile!=null){
                    return true
                }else {
                    return false
                }
            }
            if(actionType=="SaveFormFile"){
                if(order.formSignFile==null){
                    return true
                }else {
                    return false
                }
            }
            if(actionType=="Delete"){
                if(order.orderStatus=='N' && order.quotationId==null){
                    return true
                }else {
                    return false
                }
            }
            return true;
        },
        statusStyle(orderStatus){
            return {
                'badge-soft-info':orderStatus=='Q',
                'badge-soft-primary':orderStatus=='N',
                'badge-soft-success':orderStatus=='FU',
                'badge-soft-warning':orderStatus=='P',
                'badge-soft-danger':orderStatus=='F',
                'badge-soft-dark':orderStatus=='C',               
            }
        },
        myForm(){
            this.$refs.myFormModal.show()
        },
        handleCreateContract(orderData){
            if(orderData.quotationId==null){
                Vue.swal(this.$t("order.errorNoQuotation"));
            }else{
                this.curOrder = orderData
                this.$refs.contractModal.show()
            }
        },
        handleSaveContract(orderData){
            this.saveFileAction = "contract"
            this.curOrder = orderData
            this.$refs.attachment.click()
        },
        handleCreateInvoice(orderData){
            if(orderData.quotationId==null){
                Vue.swal(this.$t("order.errorNoQuotation"));
            }else{
                createOrderInvoice(orderData).then((res)=>{
                    this.handleDownload(res.data)
                }).catch(()=>{
                    Vue.swal(this.$t("order.errorCreateFail"));
                })
            }
        },
        handleSaveInvoice(orderData){
            this.saveFileAction = "invoice"
            this.curOrder = orderData
            this.$refs.attachment.click()
        },
        handleSaveFormFile(orderData){
            this.saveFileAction = "formFile"
            this.curOrder = orderData
            this.$refs.attachment.click()
        },
        changeAttachmentFile(){
            if(this.$refs.attachment.files.length==1){
                const formData = new FormData();
                formData.append("files",this.$refs.attachment.files[0]);
                uploadFile(formData).then((res)=>{
                    let fileName = res.data[0].fileOrgName
                    updateOrderFile({orderNo:this.curOrder.orderNo,fileType:this.saveFileAction,fileName:fileName}).then(()=>{
                        if(this.saveFileAction == "formFile"){
                            this.curOrder.formSignFile = fileName
                        }else if(this.saveFileAction == "contract"){
                            this.curOrder.contractFile = fileName
                        }
                        Vue.swal(this.$t("order.infoFileUpload"));
                    })
                })
            }
        },
        handleDownload (fileName){
            const url=`/api-gateway/service/download?fileType=ATT&fileName=${fileName}`
            downFile(url,"");
        },
        handleDelete(orderNo){
            Swal.fire({
              text: this.$t("order.infoAppDeleteAlert"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: this.$t("actions.ok"),
              cancelButtonText: this.$t("actions.cancel")
            }).then(result => {
              if (result.value) {
                deleteOrder(orderNo).then(()=>{
                    this.loadData()
                })
              }
            });
        },
        createContractFile(){
            let languageCode = this.contractLanguage.languageCode
            if(languageCode!=null){
                createContract(this.curOrder,languageCode).then((res)=>{
                    this.$refs.contractModal.hide()
                    this.handleDownload(res.data)
                }).catch(()=>{
                    Vue.swal(this.$t("order.errorCreateFail"));
                })
            }else{
                Vue.swal("请选择语言");
            }
        },
    }
};
</script>

<template>
            <div class="row">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3" v-if="showTitle">
                            <h5 class="card-title">{{$t("order.pageTitle")}}</h5>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                            <div>
                                <ul class="nav nav-pills">
                                    <li class="nav-item">
                                        <a class="nav-link" v-bind:class="showType=='list' ? 'active' : ''" data-bs-toggle="tooltip" title="List" @click="showType='list'"><i class="uil uil-list-ul"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" v-bind:class="showType=='grid' ? 'active' : ''" data-bs-toggle="tooltip" data-bs-placement="top" title="Grid"  @click="showType='grid'"><i class="uil uil-apps"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                
                                <b-dropdown variant="primary" menu-class="dropdown-menu-end">
                                    <template v-slot:button-content>
                                        <i class="mdi mdi-plus me-1"></i>{{$t("order.new")}}
                                    </template>
                                    <b-dropdown-item @click="handleCreate('higg')"><i class="ion ion-md-checkmark-circle-outline me-1"></i>{{$t("order.fem")}}</b-dropdown-item>
                                    <b-dropdown-item @click="handleCreate('slcp')"><i class="ion ion-md-checkmark-circle-outline me-1"></i>{{$t("order.slcp")}}</b-dropdown-item>
                                    <b-dropdown-item @click="handleCreate('aws')"><i class="ion ion-md-checkmark-circle-outline me-1"></i>{{$t("order.aws")}}</b-dropdown-item>
                                    <b-dropdown-item @click="handleCreate('zlr')"><i class="ion ion-md-checkmark-circle-outline me-1"></i>{{$t("order.zero")}}</b-dropdown-item>
                                    <b-dropdown-item @click="handleCreate('zdhcil1')"><i class="ion ion-md-checkmark-circle-outline me-1"></i>{{$t("order.zdhcil1")}}</b-dropdown-item>
                                    <b-dropdown-item @click="handleCreate('ghg')"><i class="ion ion-md-checkmark-circle-outline me-1"></i>{{$t("order.ghg")}}</b-dropdown-item>
                                    <b-dropdown-item @click="handleCreate('general')"><i class="ion ion-md-checkmark-circle-outline me-1"></i>{{$t("order.general")}}</b-dropdown-item>
                                </b-dropdown>  
                                <button type="button" class="btn btn-light text-truncate ms-2"  @click="loadData"><i class="ion ion-md-refresh me-1 align-middle"></i> {{$t("actions.refresh")}}</button>
                                <button type="button" class="btn btn-light text-truncate ms-2"  @click="myForm">{{$t("order.myApplicationForm")}}</button>
                            </div>
                        </div>
                    </div><!-- end col -->
                </div><!-- end row -->
                <b-modal id="modal-standard" title="专属申请表地址" title-class="font-18" ref="myFormModal">
                   <h5>Higg FEM申请地址:</h5>
                   <p>{{ myHiggFemFormUrl }}</p>
                   <h5>SLCP申请地址:</h5> 
                    <p>{{ mySlcpFormUrl }}</p>
                    <h5>AWS地址:</h5> 
                    <p>{{ myAwsFormUrl }}</p>   
                    <h5>SUPPLIER TO ZERO LEVEL 2 REVIEW 申请地址:</h5> 
                    <p>{{ myZeroFormUrl }}</p>  
                    <h5>ZDHC VERIFIED INCHECK LEVEL 1申请地址:</h5> 
                    <p>{{ myZdhcFormUrl }}</p>        
                    <h5>碳盘查申请地址:</h5> 
                    <p>{{ myGHGFormUrl }}</p>
                    <h5>其他服务申请地址:</h5> 
                    <p>{{ myGeneralFormUrl }}</p>
                </b-modal>
                <orderstatus :show="orderStatusShow" :data="curOrder" @close="handleCloseOrderStatusModal"></orderstatus>
                <div v-if="showType=='grid'">
                    <div class="row">
                        <div class="col-xl-4 col-sm-6" v-for="row in tableData" :key="row.orderNo">
                            <div class="card">
                                <div class="card-body p-4">
                                    <div class="d-flex align-items-start">
                                        <div class="flex-grow-1 overflow-hidden">
                                            <h5 class="font-size-15 mb-1 text-truncate">
                                                <a href="javascript:" class="text-dark"  @click="handleDetail('detail',row)">{{row.clientName}}</a>
                                            </h5>
                                            <span class="badge me-1" :class="statusStyle(row.orderStatus)">{{formatLookupText('ORDER-STATUS',row.orderStatus)}}</span>
                                            
                                        </div>
                                    </div>
                                    <p class="mt-4 text-muted">{{$t("order.orderNo")}}: {{ row.orderNo }}<br>
                                        {{$t("order.source")}}: {{formatLookupText('ORDER-SOURCE',row.orderSource)}}</p>
                                    <div class="pt-2">
                                        <button type="button" class="btn btn-primary btn-sm text-truncate ms-2"  @click="handleDetail('detail',row)"><i class="typcn typcn-document-text me-1 align-middle"></i> {{$t("actions.view")}}</button>
                                        <b-dropdown dropup variant="primary" class="ms-2" size="sm">
                                            <template v-slot:button-content>
                                                <i class="typcn typcn-cog-outline align-middle"></i> {{$t("actions.actions")}}
                                            </template>
                                            <b-dropdown-item @click="handleDetail('detail',row)" ><i class="typcn typcn-document-text me-1 align-middle"></i> {{$t("actions.view")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleDetail('edit',row)" v-if="actionEnable('Edit',row)" ><i class="typcn typcn-edit me-1"></i> {{$t("actions.edit")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleUpdateStatus(row)" v-if="actionEnable('Status',row)" ><i class="typcn typcn-time me-1"></i> {{$t("actions.status")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleFollowup(row)"  v-if="actionEnable('Follow',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.followup")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleInvoice(row)"  v-if="actionEnable('Invoice',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.invoice")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleCreateContract(row)"  v-if="actionEnable('Contract',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.createContract")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleSaveContract(row)"  v-if="actionEnable('SaveContractFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.archivingContract")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleDownload(row.contractFile)"  v-if="actionEnable('DownContractFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.downContract")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleCreateInvoice(row)"  v-if="actionEnable('CreateInvoice',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.createInvoice")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleSaveInvoice(row)"  v-if="actionEnable('SaveInvoiceFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.archivingInvoice")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleDownload(row.invoiceFile)"  v-if="actionEnable('DownInvoiceFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.downInvoice")}}</b-dropdown-item>                                            
                                            <b-dropdown-item @click="handleSaveFormFile(row)"  v-if="actionEnable('SaveFormFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.archivingApplicationForm")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleDownload(row.formSignFile)"  v-if="actionEnable('DownFormFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.downAppForm")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleDelete(row.orderNo)"  v-if="actionEnable('Delete',row)"><i class="typcn typcn-delete-outline me-1"></i> {{$t("actions.delete")}}</b-dropdown-item>
                                        </b-dropdown> 
                                    </div>
                                </div>
                                <!-- end card body -->
                            </div><!-- end card -->
                        </div><!-- end col -->
                    </div><!-- end row -->
                </div>
                <div v-if="showType=='list'">
                    <div class="table-responsive">
                        <simplebar class="h-100">
                        <table class="table align-middle table-nowrap table-check">
                            <thead>
                                <tr>
                                    <th style="width: 80px; min-width: 80px;" scope="col">{{$t("order.orderNo")}}</th>
                                    <th scope="col" v-if="showTitle" style="width: 280px;overflow: hidden;min-width: 280px;">{{$t("client.name")}}</th>
                                    <th scope="col">{{$t("order.country")}}</th>
                                    <th scope="col">{{$t("order.type")}}</th>
                                    <th scope="col">{{$t("order.status")}}</th>
                                    <th scope="col">{{$t("order.payment")}}</th>
                                    <th scope="col">{{$t("order.invoice")}}</th>
                                    <th scope="col"  style="width: 80px;overflow: hidden;min-width: 80px;">{{$t("fem.promoteBrand")}}</th>
                                    <th scope="col">{{$t("order.applicationDate")}}</th>
                                    <th style="width: 80px; min-width: 80px;">{{$t("actions.actions")}}</th>
                                </tr><!-- end tr -->
                            </thead><!-- end thead -->
                            <tbody>
                                <tr class="data-filter" v-if="showTitle">
                                    <td>
                                        <input type="text" class="form-control" v-model="filter.orderNo">
                                    </td>
                                    <td><input type="text" class="form-control" v-model="filter.clientName"></td>
                                    <td>
                                        <gpselect
                                        :data="lookupData.cnty"
                                        v-model="filter.country"
                                        />
                                    </td>
                                    <td>
                                        <gpselect
                                        :data="lookupData.orderType"
                                        v-model="filter.orderType"
                                        />
                                    </td>
                                    <td>
                                        <gpselect
                                        :data="lookupData.orderStatus"
                                        v-model="filter.orderStatus"
                                        />
                                    </td>
                                    <td>
                                        <gpselect
                                        :data="lookupData.yesNo"
                                        v-model="filter.paymentStatus"
                                        />
                                    </td>
                                    <td>
                                        <gpselect
                                        :data="lookupData.yesNo"
                                        v-model="filter.invoiceStatus"
                                        />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" v-model="filter.promoteBrand">
                                    </td>
                                    <td>
                                        <date-picker
                                            v-model="filter.createDate"
                                            format="YYYY-MM-DD"
                                            value-type="YYYY-MM-DD HH:MM:SS"
                                            :first-day-of-week="1"
                                            lang="en"
                                            ></date-picker>
                                    </td>
                                    <td>                                       
                                    </td>
                                </tr><!-- end tr -->
                                <tr v-for="row in tableData" :key="row.orderNo">
                                    <td><a class="text-body fw-medium" href="javascript:" @click="handleDetail('detail',row)">{{row.orderNo}}</a></td>
                                    <td v-if="showTitle" style="width: 280px;overflow: hidden;min-width: 280px;"> <a class="text-body fw-medium" href="javascript:" @click="handleDetail('detail',row)" :title="row.clientName" style="display: block;width:280px;">{{row.clientName}}</a></td>
                                    <td>{{formatLookupText('CNTY',row.country)}}</td>
                                    <td>{{formatLookupText('ORDER-TYPE',row.orderType)}}</td>
                                    <td><span class="badge me-1" :class="statusStyle(row.orderStatus)">{{formatLookupText('ORDER-STATUS',row.orderStatus)}}</span></td>
                                    <td>{{trueText(row.paymentStatus)}}</td>
                                    <td>{{trueText(row.invoiceStatus)}}</td>
                                    <td>{{row.promoteBrand}}</td>
                                    <td>{{row.createDate}}</td>
                                    <td>
                                        <b-dropdown variant="link" menu-class="dropdown-menu-end">
                                            <template v-slot:button-content>
                                                <button class="btn btn-light btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="uil uil-ellipsis-h"></i>
                                                </button>
                                            </template>
                                            <b-dropdown-item @click="handleDetail('detail',row)" ><i class="typcn typcn-document-text me-1"></i> {{$t("actions.view")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleDetail('edit',row)" v-if="actionEnable('Edit',row)" ><i class="typcn typcn-edit me-1"></i> {{$t("actions.edit")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleUpdateStatus(row)" v-if="actionEnable('Status',row)" ><i class="typcn typcn-time me-1"></i> {{$t("actions.status")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleFollowup(row)"  v-if="actionEnable('Follow',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.followup")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleInvoice(row)"  v-if="actionEnable('Invoice',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.invoice")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleCreateContract(row)"  v-if="actionEnable('Contract',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.createContract")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleSaveContract(row)"  v-if="actionEnable('SaveContractFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.archivingContract")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleDownload(row.contractFile)"  v-if="actionEnable('DownContractFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.downContract")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleCreateInvoice(row)"  v-if="actionEnable('CreateInvoice',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.createInvoice")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleSaveInvoice(row)"  v-if="actionEnable('SaveInvoiceFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.archivingInvoice")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleDownload(row.invoiceFile)"  v-if="actionEnable('DownInvoiceFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.downInvoice")}}</b-dropdown-item>   
                                            <b-dropdown-item @click="handleSaveFormFile(row)"  v-if="actionEnable('SaveFormFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.archivingApplicationForm")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleDownload(row.formSignFile)"  v-if="actionEnable('DownFormFile',row)"><i class="mdi mdi-folder-outline me-1"></i> {{$t("actions.downAppForm")}}</b-dropdown-item>
                                            <b-dropdown-item @click="handleDelete(row.orderNo)"  v-if="actionEnable('Delete',row)"><i class="typcn typcn-delete-outline me-1"></i> {{$t("actions.delete")}}</b-dropdown-item>
                                        </b-dropdown>                                               
                                    </td>
                                </tr><!-- end tr -->
                            </tbody><!-- end tbody -->
                        </table><!-- end table -->
                        </simplebar>
                    </div>
                    
                </div>
                <div class="row g-0 text-center text-sm-start" v-if="totalRows>0">
                    <div class="col-sm-6">
                        <div>
                            <p class="mb-sm-0">Showing {{(pageRequest.pageNum-1)*pageRequest.pageSize+1}} to {{pageRequest.pageNum*pageRequest.pageSize}} of {{totalRows}} entries</p>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-sm-6">
                        <ul class="pagination pagination-rounded justify-content-center justify-content-sm-end mb-sm-0">
                            <b-pagination v-model="pageRequest.pageNum" :total-rows="totalRows" :per-page="pageRequest.pageSize"></b-pagination>
                        </ul><!-- end ul -->
                    </div><!-- end col -->
                </div><!-- end row -->      
                <input ref="attachment" type="file" accept="image/*" aria-label="Upload your profile picture" placeholder="" style="display: none;" @change="changeAttachmentFile">     
                <b-modal
                    id="contractModal"
                    ref="contractModal"
                    centered
                    :title='$t("actions.createContract")'
                    title-class="font-18"
                    hide-footer
                    >
                    <div>
                        <multiselect v-model="contractLanguage" :options="contractLanguageList" :multiple="false"
                                label="languageName"
                                track-by="languageCode"
                        ></multiselect>
                    </div>
                    <div>
                        <button type="button" class="btn btn-primary btn-sm" @click="createContractFile">{{$t("common.ok")}}</button>
                    </div>
                </b-modal>         
            </div><!-- end card -->
</template>
<style lang="scss" scoped>
.finish{
    vertical-align: super;
    color: red;
}
.border-light {
  border-color: #f8f7fc !important;
}
.bg-light {
    background-color: rgba(248, 247, 252,1)!important;
}
.search-box{
  .form-control{
      border-radius: 30px;
      padding-left: 40px;
  }
  .search-icon{
      font-size: 16px;    
      position: absolute;
      left: 13px;
      top: 2px;
      color: #959396;
      line-height: 38px;
  }
}
</style>