<script>
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reviewSurvey:[],
      reviewRating:0
    };
  },
  mounted() {
    this.init();
  },
  watch:{
    "serviceOrder.reportReview"(){
        this.init()
    }
  },
  methods: {
    init(){
          let survey = JSON.parse(this.serviceOrder.reportReview.details)
          this.reviewRating = this.serviceOrder.reportReview.rating
          this.reviewSurvey.splice(0)
          this.reviewSurvey.push(...survey)
          this.$forceUpdate()
    },
  },
};
</script>

<template>
  <div class="mb-0">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="padding-top: 0px;">
            <div class="card border shadow-none mb-1">
                  <div class="card-header d-flex align-items-center">
                      <div class="flex-grow-1">
                          <h5 class="card-title">{{$t("serviceOrder.reviewBasic")}}</h5>
                      </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="row mb-2">
                          <div class="col-4" style="font-weight: bold;">Report Number:</div>
                          <div class="col-8">{{serviceOrder.assessmentId}}</div>
                      </div>
                      <div class="row mb-2">
                          <div class="col-4" style="font-weight: bold;">Primary Report Writer:</div>
                          <div class="col-8 username">{{serviceOrder.worker}}</div>
                      </div>       
                      <div class="row mb-2">
                          <div class="col-4" style="font-weight: bold;">Reviewer:</div>
                          <div class="col-8 username">{{serviceOrder.reviewer}}</div>
                      </div>      
                      <div class="row mb-2">
                          <div class="col-4" style="font-weight: bold;">Verification Started:</div>
                          <div class="col-8">{{serviceOrder.serviceDate}}</div>
                      </div>   
                      <div class="row mb-2">
                          <div class="col-4" style="font-weight: bold;">Verification Completed:</div>
                          <div class="col-8">{{serviceOrder.serviceDateEnd}}</div>
                      </div>   
                      <div class="row mb-2">
                          <div class="col-4" style="font-weight: bold;">Review Date:</div>
                          <div class="col-8">{{serviceOrder.reviewReportDate}}</div>
                      </div> 
                  </div>   
             </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body" style="padding-top: 0px;">
                          <div class="card border shadow-none mb-5">
                                <div class="card-header d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="card-title">{{$t("serviceOrder.reviewQuestion")}}</h5>
                                    </div>
                                </div>
                                <div class="card-body">
                                  <div class="row">
        <div class="row">
            <div class="col-8" style="font-weight: bold;">Question</div>
            <div class="col-2" style="font-weight: bold;">Rating</div>
            <div class="col-2" style="font-weight: bold;">Remark</div>
        </div>      
        <div v-for="(item) in reviewSurvey" :key="item.i18n" class="row mt-2">
          <h5>{{ item.text }}</h5>
          <div class="row mb-1" v-for="(question) in item.questionList" :key="question.i18n">
              <div class="col-8">{{ question.text }}</div>
              <div class="col-1">
                <div style="padding: 3px;"
                :class="{
                            'rateing-5': question.rating==5,
                            'rateing-4': question.rating==4,
                            'rateing-3': question.rating==3,
                            'rateing-2': question.rating==2,
                            'rateing-1': question.rating==1,
                            'rateing-0': question.rating!=''&&question.rating==0,
                            'rateing-NA': question.rating==-1,
                        }"              
                >{{question.rating==-1?'NA':question.rating}}
                </div>
              </div>
              <div class="col-3">{{question.remark}}</div>
          </div>
        </div>
        <div>
          <div class="col-8"></div>
          <div class="col-4" style="font-weight:bold">Over All:{{reviewRating}}</div>
        </div>
        <div class="row mt-5">
          Rating Used (Point 5 to 1)，<br>
          (5) Excellent: Exceeds expectations (wonderful, client will want to hug you!)<br>
          (4) Good:Meets expectations (only 1, if any, clarification on non-key sections needed)<br>
          (3) Acceptable:Meets exceptions (only 1, if any, clarification on key sections needed)<br>
          (2) Need Improvement: Did not meet expectations (considerable clarification was needed)<br>
          (1) Poor: Well below expectation (substantial clarification and revision needed, serious quality concerns to address)<br>
          (NA): Not Applicable element for this report.
        </div>
    </div>   
                                </div>
                          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.rateing-5{
  background-color: green;
  color: white;
}
.rateing-4{
  background-color: orange;
  color: white;
}
.rateing-3{
  background-color: yellow;
  color: black;
}
.rateing-2,.rateing-1,.rateing-0{
  background-color: red;
  color: white;
}
.rateing-NA{
  background-color: gray;
  color: black;
}
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
.username::first-letter {
  text-transform: uppercase;
}
</style>