<script>
import Vue from "vue";
import {downFile,fileSize,iconClass} from '@/apis/common'
import {queryServiceClientFile} from '@/apis/serviceorder'
import simplebar from "simplebar-vue"
import {saveNetDiskFile} from '@/apis/disk'
import Email from "../../components/email";
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  components: {simplebar,Email},
  data() {
    return {
      clientFileList:[],
      curFile:{
        status:"Y",
        comments:""
      },
      templateId:"NOTICE-PRE-AUDIT-RESULT",
      preAuditResultEmail:{},
      showSendMail:false
    };
  },
  computed:{
  },
  watch: {
    "serviceOrder.serviceId"(newValue){
      if(newValue!=null){
        this.loadData();
      }
    }
  },
  created(){
  },
  mounted() {
  },
  methods: {
    formatStatus(status){
      if(status==null || status==""){
        return "待审"
      }else if(status=="Y"){
        return "通过"
      }else if(status=="N"){
        return "不通过"
      }
      return ""
    },
    handleDownload (item){
        const url=`/api-gateway/service/download?fileType=SERVICE&fileName=${item.fileOrgName}`
        downFile(url,item.fileName);
    },
    loadData(){
      queryServiceClientFile(this.serviceOrder.serviceId,2).then((res)=>{
        this.clientFileList = res.data
      })
    },
    noticUser(){
      this.preAuditResultEmail.to = this.serviceOrder.order.email
      this.preAuditResultEmail.emailData = {}
      this.preAuditResultEmail.emailData.serviceId = this.serviceOrder.serviceId
      this.preAuditResultEmail.emailData.clientName = this.serviceOrder.order.clientName
      this.preAuditResultEmail.emailData.clientNameEn = this.serviceOrder.order.clientNameEn
      this.preAuditResultEmail.emailData.contact = this.serviceOrder.order.contact
      if(this.serviceOrder.order.orderType=="SLCP"){
        this.preAuditResultEmail.emailData.serviceType = "SLCP"
      }else if(this.serviceOrder.order.orderType=="Higg"){
        this.preAuditResultEmail.emailData.serviceType = "Higg FEM 2024"
      }
      this.showSendMail=true
    },
    handleSendEmailResult(res){
      if(res.code==0){
        this.showSendMail=false
      }else if(res.code==1){
        Vue.swal("已通知客户预审结果!");
        this.showSendMail=false
      }else{
        Vue.swal("发送失败，请联系管理员");
      }      
    },
    handleFileAudit(item){
      this.curFile = item
      if( item.status==null || item.status==''){
        this.curFile.status = 'N'
      }
      this.$refs.fileAuditModal.show()
    },
    handleFileAuditResult(){
      saveNetDiskFile(this.curFile).then(()=>{
        this.$refs.fileAuditModal.hide()
      })            
    },
    fileSize,
    iconClass,
  }
};
</script>

<template>
  <div class="mb-0">
    <div class="row">
      <div class="row" v-if="showSendMail">
        <email :templateId="templateId" :data="preAuditResultEmail" @send="handleSendEmailResult"></email>
      </div>
      <div class="table-responsive"  v-if="!showSendMail">
          <simplebar class="h-100">
              <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                      <tr>
                          <th scope="col">预审文件</th>
                          <th scope="col">预审状态</th>
                          <th scope="col">预审结论</th>
                          <th scope="col">更新时间</th>
                          <th scope="col">文件大小</th>
                          <th scope="col"><a href="javascript:"  @click="noticUser">通知客户</a>&nbsp; <a href="javascript:"  @click="loadData"><i class="ion ion-md-refresh me-1 align-middle" ></i> 刷新</a></th>
                      </tr>
                      </thead>
                  <tbody>
                      <tr v-for="item in clientFileList" :key="item.fileOrgName">
                          <td><a @click="handleDownload(item)" href="javascript:" class="text-dark fw-medium"><i class="mdi font-size-16 align-middle me-2" :class="iconClass(item.fileName)"></i> {{item.fileName}}</a></td>
                          <td>{{formatStatus(item.status)}}</td>
                          <td>{{item.comments}}</td>
                          <td>{{item.updateDate}}</td>
                          <td>{{fileSize(item.size)}}</td>
                          <td>
                            <a  @click="handleFileAudit(item)" href="javascript:">结论</a>&nbsp;|&nbsp;
                            <a  @click="handleDownload(item)" href="javascript:">下载</a>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </simplebar>
      </div>
    </div>
    <b-modal
          id="fileAuditModal"
          ref="fileAuditModal"
          centered
          title="文件预审结论"
          title-class="font-18"
          hide-footer
          >
            <div>
              <div class="mb-3">
                        <label class="form-label mb-3 d-flex">预审结果:</label>
                        <div class="form-check">
                          <input
                            type="radio"
                            id="status1"
                            name="status"
                            class="form-check-input"
                            value="Y"
                            v-model="curFile.status"
                          />
                          <label class="form-check-label" for="type1"
                            >通过</label
                          >
                        </div>
                        <div class="form-check">
                          <input
                            type="radio"
                            id="status2"
                            name="status"
                            class="form-check-input"
                            value="N"
                            v-model="curFile.status"
                          />
                          <label class="form-check-label" for="type2"
                            >不通过</label
                          >
                        </div>
              </div>
              <div class="mb-3">
                <label class="form-label" for="comments">意见 :</label>
                <input
                  type="text"
                  class="form-control"
                  id="comments"
                  v-model="curFile.comments"
                />
              </div>
            </div>
            <div>
              <button type="button" class="btn btn-primary btn-sm" @click="handleFileAuditResult">确认</button>
            </div>
        </b-modal>
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>