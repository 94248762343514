<script>
import Layout from "../../layouts/main";
import ServiceDetail from "./servicedetail";
import OrderDetail from "./orderdetail";
import UserFile from "./userfile";
import Survey from "./survey";
import ReviewForm from "./reviewform";
import PreAuditFile from "./preauditfile";
import WorkerFile from "./workerfile";
import Guideline from "./guideline";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import {serviceOrderDetail} from '@/apis/serviceorder'

/**
 * Tabs & accordions component
 */
export default {
  page: {
    title: "服务单明细",
    meta: [{ name: "description", content: appConfig.description }]
  },
  props:{
    serviceId:{
      type: String,
      required: true
    }
  },
  components: { Layout,ServiceDetail,OrderDetail,UserFile,WorkerFile,PreAuditFile,Survey,Guideline,ReviewForm},
  data() {
    return {
      title: "服务单明细",
      serviceOrder:{},
      lookupData:{
        clientSource:[],
        companyType:[],
        industry:[]
      },
      contactForm:{
        contact:"",
        department:"",
        title:"",
        mobile:"",
        email:""
      },
      showContactForm:false,
    };
  },
  computed:{
    showSurvey(){
      if(this.serviceOrder.survey!=null){
        return true;
      }else{
        return false;
      }
    },
    showGuideline(){
      if(this.serviceOrder.serviceType=="Higg"||this.serviceOrder.serviceType=="SLCP"){
        return true;
      }else{
        return false;
      }
    },
    showReviewForm(){
      if(this.serviceOrder.reportReview!=null&&this.serviceOrder.serviceType=="SLCP"){
        return true;
      }else{
        return false;
      }
    }
  },
  watch: {
    serviceOrder: function () {
        document.title = this.serviceOrder.serviceNo + "-" + this.$t("serviceOrder.detail")
    }
  },
  created(){
    this.init()
  },
  mounted() {
  },
  methods: {
    async init(){
      await serviceOrderDetail(this.serviceId).then((res)=>{
          this.serviceOrder = res.data
          if(this.serviceOrder.worker!=null && this.serviceOrder.worker!=""){
            this.serviceOrder.workers = this.serviceOrder.worker.split(",")
          } 
          if(this.serviceOrder.travelOption!=null && this.serviceOrder.travelOption!=""){
            this.serviceOrder.travels = this.serviceOrder.travelOption.split(",")
          }          
          if(this.serviceOrder.dataWorker!=null && this.serviceOrder.dataWorker!=""){
            this.serviceOrder.dataWorkers = this.serviceOrder.dataWorker.split(",")
          } 
          if(this.serviceOrder.dataWorkerTravelOption!=null && this.serviceOrder.dataWorkerTravelOption!=""){
            this.serviceOrder.dataTravels = this.serviceOrder.dataWorkerTravelOption.split(",")
          }      
          if(this.serviceOrder.observer!=null && this.serviceOrder.observer!=""){
            this.serviceOrder.observers = this.serviceOrder.observer.split(",")
          } 
          if(this.serviceOrder.observerTravelOption!=null && this.serviceOrder.observerTravelOption!=""){
            this.serviceOrder.observerTravels = this.serviceOrder.observerTravelOption.split(",")
          }      
      }).catch((res)=>{
        if(res.code==500){
          Swal.fire("System Error", this.$t("confirm_letter.notExist"), "error").then(()=>{
          this.$router.go(-1);
        });
        }
      })
    },
    handleCallBack(){
      this.$router.go(-1);
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <h4 class="card-title">{{serviceOrder.serviceNo}}</h4>
              </div>
              <div class="col-sm-6">
                <div class="float-end d-none d-md-block">
                  <a href="javascript:" @click="handleCallBack" class="btn btn-success">{{$t("actions.back")}}</a>
                </div>
              </div>
            </div>
            <b-tabs content-class="p-3">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-calendar-check"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{$t("serviceOrder.tabBase")}}</span>
                </template>
                <service-detail :serviceOrder="serviceOrder"></service-detail>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-clipboard-list"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{$t("serviceOrder.tabOrder")}}</span>
                </template>
                <order-detail :serviceOrder="serviceOrder"></order-detail>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{$t("serviceOrder.tabVerification")}}</span>
                </template>
                <worker-file :serviceOrder="serviceOrder"></worker-file>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-file-archive"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{$t("serviceOrder.tabPreVerified")}}</span>
                </template>
                <pre-audit-file :serviceOrder="serviceOrder"></pre-audit-file>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-file-archive"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{$t("serviceOrder.tabFactory")}}</span>
                </template>
                <user-file :serviceOrder="serviceOrder"></user-file>
              </b-tab>
              <b-tab v-if="showReviewForm">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-file-archive"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{$t("serviceOrder.reviewForm")}}</span>
                </template>
                <review-form :serviceOrder="serviceOrder"></review-form>
              </b-tab>              
              <b-tab v-if="showGuideline">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-file-archive"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{$t("serviceOrder.guideline")}}</span>
                </template>
                <guideline :serviceOrder="serviceOrder"></guideline>
              </b-tab>
              <b-tab v-if="showSurvey">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-file-archive"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">{{$t("serviceOrder.survey")}}</span>
                </template>
                <survey :serviceOrder="serviceOrder"></survey>
              </b-tab>
            </b-tabs>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>